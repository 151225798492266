import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { graphql } from "gatsby";
import { Wrapper } from "../../components/Wrapper";
import parse from "html-react-parser";
import styled from "@emotion/styled";
import Img from "gatsby-image/withIEPolyfill";
import { getImage, getPageNode } from "../../helpers/pathExtractor";

export default function OnamaPage({ data }) {
  const queryData = getPageNode(data, "allWordpressPage");
  const {
    title,
    acf: {
      onamaContent: { text, list1, list2, image },
    },
  } = queryData;

  const fluid = getImage(image, "fluid");

  return (
    <Layout>
      <SEO title="O nama" />
      <Wrapper>
        <h1>{title}</h1>
        {parse(text)}
        <ImageContainer>
          <Img fluid={fluid} />
          <div className="description">Streljana Čakovec (Kod gradskih bazena)</div>
        </ImageContainer>
        <ListContainer>
          {parse(list1)}
          {parse(list2)}
        </ListContainer>
      </Wrapper>
    </Layout>
  );
}

export const query = graphql`
  query AboutPageQuery {
    allWordpressPage(filter: { path: { eq: "/o-nama/" } }) {
      edges {
        node {
          id
          title
          acf {
            onamaContent {
              text
              list1
              list2
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 900, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ImageContainer = styled.div`
  text-align: center;

  .description {
    text-align: center;
    font-weight: 300;
    font-size: 13px;
  }
`;

const ListContainer = styled.div`
  margin-top: 20px;

  p {
    font-weight: bold;
  }
  ul {
    list-style: none;
    margin-left: 20px;
  }
`;
